import { useEffect, useState } from "react";
import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { DashboardProvider, useDashboard } from "./DashboardProvider";
import AppLayout from "../layouts/AppLayout";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import toast from "react-hot-toast";
import { TbDotsVertical } from "react-icons/tb";

const validationSchema = Yup.object().shape({
  employee_id: Yup.string().required("Employee ID is required"),
  name: Yup.string().required("Name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),
  mobile: Yup.string()
    .matches(/^\d{10}$/, "Mobile number must be exactly 10 digits")
    .required("Mobile number is required"),
  role: Yup.object()
    .shape({
      value: Yup.string().required("Role is required"),
      label: Yup.string().required("Role is required"),
    })
    .nullable()
    .required("Role is required"),
  dob: Yup.date().required("Date of birth is required"),
});

function EmployeeList() {
  const location = useLocation();
  return (
    <AppLayout active={location.pathname}>
      <div>
        <DashboardProvider>
          <EmployeeDetail />
        </DashboardProvider>
      </div>
    </AppLayout>
  );
}

export default EmployeeList;

const EmployeeDetail = () => {
  const dashboardContext = useDashboard();

  const [isActive, setIsActive] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmp, setSelectedEmp] = useState(null);
  const [activeBtn, setActiveBtn]: [string, Function] = useState("");
  const [metaData, setMetaData]: [any, Function] = useState();
  const [page, setPage]: [number, Function] = useState(1);

  const getEmployList = async () => {
    try {
      let requestBody = {
        limit: 10,
        page: page,
      };
      let response = await dashboardContext?.handleGetEmployee(requestBody);
      if (response?.employees) {
        setEmployeeList(response.employees);
        setMetaData(response?.meta);
      }
    } catch (error) {}
  };

  const columns = [
    {
      id: "name",
      name: "Name",
      selector: (row: any) => row.name,
    },
    {
      id: "email",
      name: "Email",
      selector: (row: any) => row.email,
    },
    {
      id: "employee_id",
      name: "Employee ID",
      selector: (row: any) => row.employee_id,
    },
    {
      id: "dob",
      name: "Date of Birth",
      selector: (row: any) => row.dob,
    },
    {
      id: "mobile",
      name: "Phone Number",
      selector: (row: any) => row.mobile,
    },

    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <div className="pt-2 Actions">
            <Dropdown className="view">
              <Dropdown.Toggle id="dropdown-basic" className="view_dropdown">
                <TbDotsVertical color="black" fontSize={21} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setSelectedEmp(row);
                    setActiveBtn("edit");
                    setIsActive(true);
                  }}
                >
                  View
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getEmployList();
  }, [page]);

  return (
    <>
      <div className="emp_list">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className=" fw-semibold ">
            Employees&nbsp;{metaData?.total_count}
          </h6>
          <Button
            className="btn-sm d-flex align-items-center gap-2 py-2 btn btn-primary btn btn-primary"
            onClick={() => {
              setIsActive(true);
              setActiveBtn("apply");
              setSelectedEmp(null);
            }}
          >
            Add Employee
          </Button>
        </div>
        <DataTable
          className="data-table"
          columns={columns}
          data={employeeList}
          highlightOnHover
          progressPending={!employeeList}
          paginationPerPage={metaData?.limit}
          paginationTotalRows={metaData?.total_count}
          paginationDefaultPage={1}
          pagination
          paginationServer
          onChangePage={(page) => {
            setPage(page);
          }}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
        />
        {isActive && (
          <CreateEmployeeModal
            show={isActive}
            handleClose={() => setIsActive(false)}
            getEmployList={getEmployList}
            activeBtn={activeBtn}
            selectedEmp={selectedEmp}
          />
        )}
      </div>
    </>
  );
};

function CreateEmployeeModal({
  show,
  handleClose,
  getEmployList,
  activeBtn,
  selectedEmp,
}: any) {
  const dashboardContext = useDashboard();
  const roleOptions = [
    { value: "admin", label: "Admin" },
    { value: "employee", label: "Employee" },
  ];

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {activeBtn === "edit" ? "Employee Detail" : "Create Employee"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={
            selectedEmp
              ? {
                  employee_id: selectedEmp?.employee_id,
                  name: selectedEmp?.name,
                  email: selectedEmp?.email,
                  mobile: selectedEmp?.mobile,
                  role: roleOptions.find(
                    (option) => option.value === selectedEmp?.role
                  ),
                  dob: selectedEmp?.dob,
                }
              : {
                  employee_id: "",
                  name: "",
                  email: "",
                  mobile: "",
                  role: null,
                  dob: "",
                }
          }
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const employeeData = {
                ...values,
                role: values?.role?.value,
              };

              let response = await dashboardContext?.createEmployeeList(
                employeeData
              );
              console.log(response, "sdasdads");

              if (response?.success) {
                getEmployList();
                handleClose();
              } else {
                toast?.error(response?.message);
              }
            } catch (error) {
              console.error(error);
            }
            setSubmitting(false);
            handleClose();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            setFieldValue,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              <Form.Group controlId="formEmpId">
                <Form.Label>
                  Employee ID<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Employee ID"
                  name="employee_id"
                  value={values.employee_id}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.employee_id && !!errors.employee_id}
                />
                <Form.Control.Feedback type="invalid">
                  {(Array.isArray(errors.employee_id) &&
                    errors.employee_id.join(", ")) ||
                    "Employee ID is required"}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formName" className="mt-3">
                <Form.Label>
                  Name<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter name"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.name && !!errors.name}
                />

                <Form.Control.Feedback type="invalid">
                  {(Array.isArray(errors.name) && errors.name.join(", ")) ||
                    "Name is required"}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formEmail" className="mt-3">
                <Form.Label>
                  Email<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="email"
                  placeholder="Enter email"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.email && !!errors.email}
                />
                <Form.Control.Feedback type="invalid">
                  {(Array.isArray(errors.email) && errors.email.join(", ")) ||
                    "Email is required"}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group controlId="formMobile" className="mt-3">
                <Form.Label>
                  Mobile Number<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter mobile number"
                  name="mobile"
                  value={values.mobile}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.mobile && !!errors.mobile}
                />
                <Form.Control.Feedback type="invalid">
                  {(Array.isArray(errors.email) && errors.email.join(", ")) ||
                    "Email is required"}
                </Form.Control.Feedback>
                {/* <Form.Control.Feedback type="invalid">
                  {errors.mobile}
                </Form.Control.Feedback> */}
              </Form.Group>

              <Form.Group controlId="formRole" className="mt-3">
                <Form.Label>
                  Role<span className="mendatory">*</span>
                </Form.Label>
                <Select
                  name="role"
                  value={values.role}
                  options={roleOptions}
                  onChange={(option) => setFieldValue("role", option)}
                  onBlur={handleBlur}
                  // isInvalid={touched.role && !!errors.role}
                />
                {touched.role && !!errors.role && (
                  <div className="invalid-feedback d-block">{errors.role}</div>
                )}
              </Form.Group>

              <Form.Group controlId="formDOB" className="mt-3">
                <Form.Label>
                  Date of Birth<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="dob"
                  value={values.dob}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.dob && !!errors.dob}
                />

                <Form.Control.Feedback type="invalid">
                  {(Array.isArray(errors.dob) && errors.dob.join(", ")) ||
                    "DOB is required"}
                </Form.Control.Feedback>
              </Form.Group>
              {activeBtn !== "edit" && (
                <>
                  <div className="d-flex justify-content-end">
                    <Button
                      variant="primary"
                      type="submit"
                      className="mt-3 btn-sm d-flex align-items-center gap-2 py-2 btn btn-primary btn btn-primary"
                    >
                      Submit
                    </Button>
                  </div>
                </>
              )}
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
