import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, FormikHelpers } from "formik";
import * as Yup from "yup";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useAuthentication } from "./AuthenticationProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

interface LoginFormValues {
  email: string;
  password: string;
}

const initialValues: LoginFormValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object({
  email: Yup.string().email("Invalid email address").required("Required"),
  password: Yup.string().required("Required"),
});

const SignInForm = ({ setIsExtension }: { setIsExtension: Function }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const authContext = useAuthentication();
  const [showPassword, setShowPassword] = useState(false);

  const handleSubmit = (
    values: LoginFormValues,
    { setSubmitting }: FormikHelpers<LoginFormValues>
  ) => {
    setTimeout(async () => {
      try {
        let response = await authContext?.handleSignIn(values);

        if (response?.token) {
          navigate(routes.leave);
          localStorage.setItem("auth-token", response?.token);
          localStorage.setItem("role", response?.user?.role);

          toast.success("login successful");
        } else {
          toast.error(response?.message?.[0]);
        }
      } catch (error) {}
      setSubmitting(false);
    }, 400);
  };
  useEffect(() => {
    setIsExtension(location?.pathname);
  }, [location?.pathname]);

  return (
    <div className="login-container">
      <Container>
        <Row>
          <Col md={6} lg={4} className="mx-auto">
            <div className="login-form">
              <h2 className="fw-semibold">Login</h2>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isSubmitting, isValid, dirty }) => (
                  <Form>
                    <div className="form-group mb-3">
                      <label htmlFor="email">Email Address</label>
                      <Field
                        type="email"
                        name="email"
                        id="email"
                        className="form-control"
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="form-group mb-3">
                      <label htmlFor="password">Password</label>
                      <div className="input-container">
                        <Field
                          type={showPassword ? "text" : "password"}
                          name="password"
                          id="password"
                          className="form-control"
                        />
                        <FontAwesomeIcon
                          icon={showPassword ? faEyeSlash : faEye}
                          className="eye-icon"
                          onClick={() => setShowPassword(!showPassword)}
                        />
                      </div>
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="text-danger"
                      />
                    </div>
                    <div className="text-center">
                      <Button
                        type="submit"
                        disabled={isSubmitting || !isValid || !dirty}
                        className="btn btn-primary"
                      >
                        {isSubmitting ? "Please wait..." : "Sign In"}
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignInForm;
