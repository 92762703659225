import { useState } from "react";
import "./App.css";
import LandingExtension from "./LandingExtension";
import Layout from "./Layout";
import { AuthenticationProvider } from "./components/auth/AuthenticationProvider";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";

function App() {
  const [isExtesion, setIsExtension]: [boolean, Function] = useState(false);
  console.log(
    isExtesion,
    "isExtesionisExtesionisExtesionisExtesionisExtesionisExtesion"
  );

  return (
    <>
      {/* <LandingExtension isExtesion={isExtesion} /> */}
      <AuthenticationProvider>
        <Layout setIsExtension={setIsExtension} />
        <Toaster />
      </AuthenticationProvider>
    </>
  );
}

export default App;
