import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useAuthentication } from "../auth/AuthenticationProvider";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

const ChangePassword = () => {
  const authContext = useAuthentication();
  const navigate = useNavigate();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const validationSchema = Yup.object({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
      .matches(/[a-z]/, "Password must contain at least one lowercase letter")
      .matches(/[0-9]/, "Password must contain at least one number")
      .required("New password is required"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword")], "Passwords must match")
      .required("Confirm password is required"),
  });

  const handleSubmit = async (values: any) => {
    try {
      let requestBody = {
        password: values.currentPassword,
        newPassword: values.newPassword,
      };
      let response = await authContext?.UpdatePassword(
        authContext?.loggedInUserDetail?.id,
        requestBody
      );
      if (response?.data?.success) {
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  return (
    <div className="container_changepassword">
      <div className="change-password-box">
        <div className="row">
          <div className="col-6">
            <h2 className="change-password">Change Password</h2>
            <ul className="password-requirements">
              <li>At least 6 characters</li>
              <li>At least 1 upper-case letter (A-Z)</li>
              <li>At least 1 lower-case letter (a-z)</li>
              <li>At least 1 number (0-9)</li>
            </ul>
          </div>
          <div className="col-6">
            <Formik
              initialValues={{
                currentPassword: "",
                newPassword: "",
                confirmPassword: "",
              }}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ errors, touched }) => (
                <Form>
                  <div className="input-container">
                    <Field
                      type={showCurrentPassword ? "text" : "password"}
                      name="currentPassword"
                      placeholder="Current Password"
                    />
                    <span
                      className="toggle-visibility"
                      onClick={() =>
                        setShowCurrentPassword(!showCurrentPassword)
                      }
                    >
                      <FontAwesomeIcon
                        icon={showCurrentPassword ? faEyeSlash : faEye}
                      />
                    </span>
                    <ErrorMessage
                      name="currentPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="input-container">
                    <Field
                      type={showNewPassword ? "text" : "password"}
                      name="newPassword"
                      placeholder="New Password"
                    />
                    <span
                      className="toggle-visibility"
                      onClick={() => setShowNewPassword(!showNewPassword)}
                    >
                      <FontAwesomeIcon
                        icon={showNewPassword ? faEyeSlash : faEye}
                      />
                    </span>
                    <ErrorMessage
                      name="newPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <div className="input-container">
                    <Field
                      type={showConfirmPassword ? "text" : "password"}
                      name="confirmPassword"
                      placeholder="Confirm New Password"
                    />
                    <span
                      className="toggle-visibility"
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      <FontAwesomeIcon
                        icon={showConfirmPassword ? faEyeSlash : faEye}
                      />
                    </span>
                    <ErrorMessage
                      name="confirmPassword"
                      component="div"
                      className="error-message"
                    />
                  </div>
                  <button type="submit" className="btn btn_css p-2">
                    Save
                  </button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
