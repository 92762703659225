import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { DashboardProvider, useDashboard } from "./DashboardProvider";
import { Formik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DataTable, { TableColumn } from "react-data-table-component";
import { useLocation } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import Select from "react-select";
import { useAuthentication } from "../auth/AuthenticationProvider";
import { TbDotsVertical } from "react-icons/tb";

export const validationSchema = Yup.object({
  date: Yup.date().required("Date is required"),
  leave: Yup.boolean().required("Leave is required"),
  wfh: Yup.boolean().required("Work from home is required"),
  project: Yup.string().required("Project is required"),
  description: Yup.string().required("Description is required"),
  hours: Yup.number()
    .min(0, "Hours must be a positive number")
    .required("Hours are required"),
});

interface Project {
  id: string;
  title: string;
}

interface Timesheet {
  id: string;
  date: string;
  leave: boolean;
  wfh: boolean;
  project: Project;
  description: string;
  hours: number;
  employee: any;
}

interface FormValues {
  date: string;
  leave: boolean;
  wfh: boolean;
  project: string;
  description: string;
  hours: number;
}

function TimeSheet() {
  const location = useLocation();
  return (
    <AppLayout active={location.pathname}>
      <div>
        <DashboardProvider>
          <TimeSheetDetail />
        </DashboardProvider>
      </div>
    </AppLayout>
  );
}

export default TimeSheet;

const TimeSheetDetail = () => {
  const dashboardContext = useDashboard();
  const authContext = useAuthentication();

  const [isActive, setIsActive] = useState<boolean>(false);
  const [timesheetList, setTimesheetList] = useState<Timesheet[]>([]);
  const [activeBtn, setActiveBtn] = useState<string>("");
  const [selectedTimesheet, setSelectedTimesheet] = useState<Timesheet | null>(
    null
  );
  const [isAdmin, setIsAdmin]: [boolean, Function] = useState(false);
  const [metaData, setMetaData]: [any, Function] = useState();
  const [page, setPage]: [number, Function] = useState(1);

  useEffect(() => {
    if (
      selectedTimesheet &&
      authContext?.loggedInUserDetail?.id === selectedTimesheet?.id
    ) {
      setIsAdmin(true);
    }
  }, [selectedTimesheet]);

  const getTimesheet = async () => {
    try {
      let response = await dashboardContext?.handleGetTimesheet({
        limit: 10,
        page: page,
      });
      if (response?.success) {
        setTimesheetList(response?.timesheets);
        setMetaData(response?.metaData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const columns: TableColumn<Timesheet>[] = [
    {
      id: "employee",
      name: "Employee Name",
      selector: (row) => row?.employee?.name,
    },
    {
      id: "project",
      name: "Project",
      selector: (row) => row?.project?.title,
    },
    // {
    //   id: "leave",
    //   name: "Leave",
    //   cell: (row) => (
    //     <div className="pt-2 Actions">{row?.leave ? "Yes" : "No"}</div>
    //   ),
    // },
    {
      id: "wfh",
      name: "Work From Home",
      cell: (row) => (
        <div className="pt-2 Actions">{row?.wfh ? "Yes" : "No"}</div>
      ),
    },
    {
      id: "date",
      name: "Date",
      selector: (row) => moment(row.date).format("YYYY-MM-DD"),
    },
    {
      id: "hours",
      name: "Hours",
      selector: (row) => row?.hours,
    },
    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row) => {
        return (
          <div className="pt-2 Actions">
            <Dropdown className="view">
              <Dropdown.Toggle id="dropdown-basic" className="view_dropdown">
                <TbDotsVertical color="black" fontSize={21} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    setIsActive(true);
                    setActiveBtn("edit");
                    setSelectedTimesheet(row);
                  }}
                >
                  Edit
                </Dropdown.Item>
                {authContext?.loggedInUserDetail?.id === row?.employee?.id && (
                  <Dropdown.Item
                    onClick={async () => {
                      let response =
                        await dashboardContext?.handleDeleteTimeSheet(row?.id);
                      if (response?.success) {
                        toast.success("Timesheet deleted successfully");
                        getTimesheet();
                      } else {
                        toast.error(response?.message);
                      }
                    }}
                  >
                    Delete
                  </Dropdown.Item>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getTimesheet();
  }, [page]);

  return (
    <div className="emp_list">
      <div className="d-flex justify-content-between align-items-center">
        <h6 className="fw-semibold">Timesheet</h6>
        <Button
          className="btn-sm d-flex align-items-center gap-2 py-2 btn btn-primary btn btn-primary"
          onClick={() => {
            setIsActive(true);
            setActiveBtn("add");
            setSelectedTimesheet(null);
          }}
        >
          Add Timesheet
        </Button>
      </div>

      <DataTable
        className="data-table"
        columns={columns}
        data={timesheetList}
        highlightOnHover
        progressPending={!timesheetList}
        paginationPerPage={metaData?.limit}
        paginationTotalRows={metaData?.total_count}
        paginationDefaultPage={1}
        pagination
        paginationServer
        onChangePage={(page) => {
          setPage(page);
        }}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
      />

      {isActive && (
        <CreateTimeSheetModal
          show={isActive}
          handleClose={() => setIsActive(false)}
          getTimesheet={getTimesheet}
          activeBtn={activeBtn}
          timesheet={selectedTimesheet}
          isAdmin={isAdmin}
        />
      )}
    </div>
  );
};

interface CreateTimeSheetModalProps {
  show: boolean;
  handleClose: () => void;
  getTimesheet: () => void;
  activeBtn: string;
  timesheet: Timesheet | null;
  isAdmin: boolean;
}

function CreateTimeSheetModal({
  show,
  handleClose,
  getTimesheet,
  activeBtn,
  timesheet,
  isAdmin,
}: CreateTimeSheetModalProps) {
  const authContext = useAuthentication();
  const dashboardContext = useDashboard();
  const projectList = dashboardContext?.projectList || [];
  const projectOptions = projectList.map((project: Project) => ({
    value: project?.id,
    label: project?.title,
  }));

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {activeBtn === "edit" ? "Timesheet Detail" : "Create Timesheet"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={
            timesheet
              ? {
                  date: moment(timesheet?.date).format("YYYY-MM-DD"),
                  leave: timesheet?.leave,
                  wfh: timesheet?.wfh,
                  project: timesheet?.project?.id,
                  description: timesheet?.description,
                  hours: timesheet?.hours,
                }
              : {
                  date: "",
                  leave: false,
                  wfh: false,
                  project: "",
                  description: "",
                  hours: 0,
                }
          }
          validationSchema={validationSchema}
          onSubmit={async (
            values: FormValues,
            { setSubmitting }: FormikHelpers<FormValues>
          ) => {
            try {
              const employeeData = { ...values };
              let response = await (activeBtn === "edit"
                ? dashboardContext?.updateTimesheet(timesheet?.id, employeeData)
                : dashboardContext?.createTimesheetList(employeeData));

              if (response?.success) {
                getTimesheet();
                handleClose();
                toast.success(
                  activeBtn === "edit"
                    ? "Timesheet updated successfully."
                    : "Timesheet created successfully."
                );
              } else {
                toast.error(response?.message);
              }
            } catch (error) {
              console.error(error);
              toast.error("An error occurred. Please try again.");
            }
            setSubmitting(false);
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
            setFieldValue,
          }) => (
            <>
              {authContext?.loggedInUserDetail?.id ===
                timesheet?.employee?.id || activeBtn === "add" ? (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group controlId="formDate" className="mt-3">
                    <Form.Label>
                      Date<span className="mendatory">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="date"
                      value={values.date}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.date && !!errors.date}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.date}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="row">
                    {/* <div className="col-md-6">
                      <Form.Group controlId="formLeave" className="mt-3">
                        <Form.Label>
                          Leave<span className="mendatory">*</span>
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          name="leave"
                          label={values.leave ? "Yes" : "No"}
                          checked={values.leave}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.leave && !!errors.leave}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.leave}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div> */}

                    <div className="col-md-6">
                      <Form.Group controlId="formwfh" className="mt-3">
                        <Form.Label>
                          Work from Home<span className="mendatory">*</span>
                        </Form.Label>
                        <Form.Check
                          type="switch"
                          name="wfh"
                          label={values.wfh ? "Yes" : "No"}
                          checked={values.wfh}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={touched.wfh && !!errors.wfh}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.wfh}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>

                  <Form.Group controlId="formProject" className="mt-3">
                    <Form.Label>
                      Project<span className="mendatory">*</span>
                    </Form.Label>
                    <Select
                      options={projectOptions}
                      placeholder="Select a project"
                      name="project"
                      value={projectOptions.find(
                        (option) => option.value === values.project
                      )}
                      onChange={(selectedOption) =>
                        setFieldValue(
                          "project",
                          selectedOption ? selectedOption.value : ""
                        )
                      }
                      onBlur={handleBlur}
                      className={
                        touched.project && !!errors.project ? "is-invalid" : ""
                      }
                    />
                    {touched.project && errors.project && (
                      <div className="invalid-feedback d-block">
                        {errors.project}
                      </div>
                    )}
                  </Form.Group>

                  <Form.Group controlId="formHours" className="mt-3">
                    <Form.Label>
                      Hours<span className="mendatory">*</span>
                    </Form.Label>
                    <Form.Control
                      type="number"
                      placeholder="Enter hours"
                      name="hours"
                      value={values.hours.toString()}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.hours && !!errors.hours}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.hours}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formDescription" className="mt-3">
                    <Form.Label>
                      Description<span className="mendatory">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter description"
                      name="description"
                      value={values.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {authContext?.loggedInUserDetail?.id ===
                    timesheet?.employee?.id || activeBtn === "add" ? (
                    <Button
                      variant="primary"
                      type="submit"
                      className="mt-3"
                      disabled={isSubmitting}
                    >
                      {activeBtn === "edit" ? "Update" : "Submit"}
                    </Button>
                  ) : null}
                </Form>
              ) : (
                <>
                  <div>
                    <label>Description</label>
                  </div>
                  <Form.Group controlId="formDescription" className="mt-3">
                    <Form.Control
                      as="textarea"
                      placeholder="Enter description"
                      name="description"
                      value={values.description}
                      style={{ height: "350px" }}
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.description && !!errors.description}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.description}
                    </Form.Control.Feedback>
                  </Form.Group>
                </>
              )}
            </>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
