import { useLocation } from "react-router-dom";
import { DashboardProvider } from "../Dashboard/DashboardProvider";
import AppLayout from "../layouts/AppLayout";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { Modal, Button } from "react-bootstrap";
import { useState } from "react";

const absentEmployeesData: { [date: string]: string[] } = {
  "2024-06-12": ["Ayesha", "Chitra"],
  "2024-06-15": ["Tejesh"],
  "2024-06-20": ["Pawan", "Tom"],
  "2025-06-20": ["Pawan", "Tom"],
};

function Calendar() {
  const location = useLocation();
  return (
    <AppLayout active={location.pathname}>
      <div>
        <DashboardProvider>
          <CalendarSection />
        </DashboardProvider>
      </div>
    </AppLayout>
  );
}

export default Calendar;

const CalendarSection = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const [absentEmployees, setAbsentEmployees] = useState<string[]>([]);

  const handleDateClick = (arg: DateClickArg) => {
    const dateStr = arg.dateStr;
    setSelectedDate(dateStr);
    setAbsentEmployees(absentEmployeesData[dateStr] || []);
    setModalIsOpen(true);
  };

  return (
    <div className="calendar-container">
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        dateClick={handleDateClick}
        events={[
          { title: "Project Meeting", date: "2024-06-12" },
          { title: "Webinar", date: "2024-06-15" },
          { title: "Conference", date: "2024-06-20" },
        ]}
      />

      <CustomModal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        date={selectedDate}
        employees={absentEmployees}
      />
    </div>
  );
};

interface CustomModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  date: string;
  employees: string[];
}

const CustomModal = ({
  isOpen,
  onRequestClose,
  date,
  employees,
}: CustomModalProps) => {
  return (
    <Modal show={isOpen} onHide={onRequestClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Absent Employees on {date}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {employees?.length > 0 ? (
          <ul className="employee-list">
            {employees?.map((employee, index) => (
              <li key={index} className="employee-item">
                {employee}
              </li>
            ))}
          </ul>
        ) : (
          <p>No employees are absent on this day.</p>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onRequestClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
