import axios from "axios";

// const REACT_APP_BASE_URL = "http://localhost:3001";
// const REACT_APP_BASE_URL = "http://128.199.25.227:3000";

// export const BASE_URL = REACT_APP_BASE_URL;
export const BASE_URL = process.env.REACT_APP_BASE_URL;

const filterQueryParams = (params: any = {}) => {
  Object.keys(params).forEach((key) => {
    if (params[key] === null || params[key]?.length < 1 || params[key] === "") {
      delete params[key];
    }
  });
  let rating: Array<number> = [];
  if (params?.rating?.length) {
    rating = [...params.rating];
    delete params.rating;
  }
  const searchParams = new URLSearchParams(params);
  rating.map((rate) => searchParams.append("rating", rate.toString()));
  return searchParams.toString();
};

const generateAuthHeader = (config: any = {}) => {
  const token: string | null = localStorage.getItem("auth-token");
  if (token && token.length > 0) {
    config.headers = { Authorization: `Bearer ${token}` };
  }
  return config;
};

// export const _delete = async (url: string, _config: any = {}) => {
//   const config: any = generateAuthHeader({ ..._config });
//   return await axios.delete(url, { ...config }).then((res: any) => res.data);
// };

const _delete = async (url: string, params?: any, _config: any = {}) => {
  let config = {};
  config = generateAuthHeader({ ..._config });
  // if (token) {
  //   config.headers = { Authorization: `Bearer ${token}` };
  // }
  return await axios
    .delete(url, { ...config, data: params })
    .then((res: any) => {
      if (res.status) {
        return res.data;
      } else {
        return res.data;
      }
    })
    .catch(({ response }: any) => {
      return response.data;
    });
};

const post = async (url: string, data = {}, _config = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return axios
    .post(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }: any) => {
      return response;
    });
};

export const put = async (url: string, data = {}, _config = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return axios
    .put(url, data, { ...config })
    .then((res: any) => {
      if (res.status) {
        const { data, status } = res;
        return { data, status };
      } else {
        return {
          status: 200,
          data: res,
        };
      }
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        return response.data;
      }
      return response;
    });
};

const get = async (url: string, _config: any = {}) => {
  const config: any = generateAuthHeader({ ..._config });
  return await axios.get(url, { ...config }).then((res: any) => res.data);
};

export const api = {
  signIn: async (params: { username: string; password: string }) => {
    try {
      const res = await post(`${BASE_URL}/auth/employeeLogin`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      } else if (res?.statusCode === 401) {
        return {
          error: true,
          message: ["Incorrect Email Address or password."],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },
  createEmployee: async (params: any) => {
    try {
      const res = await post(`${BASE_URL}/employee/createEmployee`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },

  // updateEmp: (id: any, params: any) => {
  //   return put(
  //     `${BASE_URL}/timesheet/updateTimesheetByTimesheetId/${id}`,
  //     params
  //   );
  // },

  getEmployee: (params: any) => {
    return get(
      `${BASE_URL}/employee/getAllEmployee?${filterQueryParams(params)}`
    );
  },

  createTimeSheet: async (params: any) => {
    try {
      const res = await post(`${BASE_URL}/timesheet/createTimesheet`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },
  getTimesheet: (params: any) => {
    return get(
      `${BASE_URL}/timesheet/getAllTimesheet?${filterQueryParams(params)}`
    );
  },
  deleteTimesheet: (id: any) => {
    return _delete(`${BASE_URL}/timesheet/deleteTimesheetByTimesheetId/${id}`);
  },
  updateTimeSheet: (id: any, params: any) => {
    return put(
      `${BASE_URL}/timesheet/updateTimesheetByTimesheetId/${id}`,
      params
    );
  },

  getTimesheetById: (id: any) => {
    return get(`${BASE_URL}/timesheet/getTimesheetByTimesheetId/${id}`);
  },

  createProject: async (params: any) => {
    try {
      const res = await post(`${BASE_URL}/projects/createProject`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },
  getProject: (params: any) => {
    return get(
      `${BASE_URL}/projects/getAllProject?${filterQueryParams(params)}`
    );
  },
  // deleteProject: (id: any) => {
  //   return _delete(`${BASE_URL}/projects/deleteProjectByProjectId/${id}`);
  // },

  deleteProject: (id: any) => {
    return _delete(`${BASE_URL}/projects/deleteProjectByProjectId/${id}`);
  },
  updateProject: (id: any, params: any) => {
    return put(`${BASE_URL}/projects/updateProjectByProjectId/${id}`, params);
  },

  getUser: () => {
    return get(`${BASE_URL}/auth/me`);
  },

  updatePassword: (id: any, params: any) => {
    return put(`${BASE_URL}/employee/${id}/changePassword`, params);
  },

  updateProfile: (id: any, params: any) => {
    return put(`${BASE_URL}/employee/${id}/updateProfile`, params);
  },

  // .........leave......................

  createLeave: async (params: any) => {
    try {
      const res = await post(`${BASE_URL}/employee/apply_leave`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },

  getLeave: (params: any) => {
    return get(
      `${BASE_URL}/employee/getAllLeaves?${filterQueryParams(params)}`
    );
  },
  UpdateLeaveById: (id: any, params: any) => {
    return put(`${BASE_URL}/employee/${id}/updateLeave`, params);
  },
  deleteLeaveById: (id: any) => {
    return _delete(`${BASE_URL}/employee/deleteLeave/${id}`);
  },

  // ....................work from home.............

  createWorkFromHome: async (params: any) => {
    try {
      const res = await post(`${BASE_URL}/employee/apply_workFromHome`, params);
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },

  getWorkFromHome: (params: any) => {
    return get(
      `${BASE_URL}/employee/getAllWorkFromHome?${filterQueryParams(params)}`
    );
  },
  UpdateWorkFromHomeId: (id: any, params: any) => {
    return put(`${BASE_URL}/employee/${id}/updateWorkFromHome`, params);
  },
  deleteWorkFromHomeId: (id: any) => {
    return _delete(`${BASE_URL}/employee/deleteWorkFromHome/${id}`);
  },

  // .........accept and reject leave.............
  acceptAndRejectLeave: (params: any) => {
    return put(`${BASE_URL}/employee/updateLeaveStatus`, params);
  },

  acceptAndRejectWfh: (params: any) => {
    return put(`${BASE_URL}/employee/updateWorkFromHomeStatus`, params);
  },

  // ..........................biometric...........................
  uploadBiometricList: async (params: any) => {
    try {
      const formData = new FormData()
      formData.set('date', params.date)
      formData.append('file', params.file)

      const res = await post(`${BASE_URL}/attendance/upload`, formData);
      console.info(res.data)
      if (res?.status === 400) {
        return {
          error: true,
          message: [res.data.message || res.data],
        };
      }
      return res.data;
    } catch (e) {
      return {
        error: true,
        message: e,
      };
    }
  },

  getAllBiometric: async (params: any) => {
    return get(`${BASE_URL}/attendance/all?${filterQueryParams(params)}`);
  },
};
