import React, { useState, useEffect, useRef, useCallback } from "react";
import { useLocation } from "react-router-dom";
import {
  DashboardProvider,
  useDashboard,
} from "../Dashboard/DashboardProvider";
import AppLayout from "../layouts/AppLayout";
import { Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { BsCloudUploadFill } from "react-icons/bs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { RxCrossCircled } from "react-icons/rx";
import toast from "react-hot-toast";
import moment from "moment";

function BioMetric() {
  const location = useLocation();

  return (
    <AppLayout active={location.pathname}>
      <DashboardProvider>
        <BioMetricDetail />
      </DashboardProvider>
    </AppLayout>
  );
}

export default BioMetric;

const BioMetricDetail = () => {
  const dashboardContext = useDashboard();
  const [date, setDate] = useState<any>(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const [isActive, setIsActive] = useState(false);
  const [attendanceList, setAttendanceList] = useState([]);
  const [activeBtn, setActiveBtn] = useState("");
  const [selectedWfh, setSelectedWfh] = useState(null);
  const [wfhId] = useState("");
  const [metaData, setMetaData] = useState<any>();
  const [page, setPage] = useState(1);

  const getBioMetricList = useCallback(async () => {
    try {
      let requestBody = {
        limit: 10,
        page: page,
        date,
      };
      const response = await dashboardContext?.getBiometricList(requestBody);

      if (response?.success) {
        setAttendanceList(response?.attendance);
        setMetaData(response?.meta);
      }
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, page]);

  const columns = [
    {
      id: "employee_id",
      name: "Employee Name",
      selector: (row: any) => row.employee?.name,
    },
    {
      id: "startDate",
      name: "Date",
      selector: (row: any) => moment(row.date).format("DD MMM, YYYY"),
    },
    {
      id: "endDate",
      name: "Checkin Time",
      selector: (row: any) => row.punchIn,
    },
    {
      id: "endDate",
      name: "Checkout Time",
      selector: (row: any) => row.punchOut,
    },
    {
      id: "Hours",
      name: "Hours",
      selector: (row: any) => Math.round(row.hours),
    },
    {
      id: "status",
      name: "Status",
      cell: (row: any) => {
        return (
          <div
            className={` ${
              row?.status === "rejected"
                ? "rejected-badge"
                : row?.status === "approved"
                ? "accept-badge"
                : "draft-badge"
            }`}
          >
            {row.status === "rejected"
              ? "Present"
              : row?.status === "approved"
              ? "Absent"
              : row?.status === "requested"
              ? "Requested"
              : row?.status}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getBioMetricList();
  }, [getBioMetricList]);

  return (
    <>
      <div>
        <div className='d-flex justify-content-between align-items-center'>
          <h5 className=' fw-bold mt-2'>Biometric</h5>
          <div className='d-flex'>
            <div className="me-3">
            <DatePicker
              className='date_picker'
              selected={date}
              onChange={(date: any) =>
                setDate(moment(date).format("YYYY-MM-DD"))
              }
            />
            </div>
            <Button
              className='btn btn_css p-2'
              style={{height: "46px"}}
              onClick={() => {
                setIsActive(true);
                setActiveBtn("add");
                setSelectedWfh(null);
              }}
            >
              Upload Biometric
            </Button>
          </div>
        </div>

        <DataTable
          className='data-table'
          columns={columns}
          data={attendanceList}
          highlightOnHover
          progressPending={!attendanceList}
          paginationPerPage={metaData?.limit}
          paginationTotalRows={metaData?.total_count}
          paginationDefaultPage={1}
          pagination
          paginationServer
          onChangePage={(page) => {
            setPage(page);
          }}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
        />

        {isActive && (
          <CreateWfhModal
            show={isActive}
            handleClose={() => setIsActive(false)}
            getBioMetricList={getBioMetricList}
            activeBtn={activeBtn}
            wfh={selectedWfh}
            wfhId={wfhId}
          />
        )}
      </div>
    </>
  );
};

function CreateWfhModal({ show, handleClose, getBioMetricList }: any) {
  const dashboardContext = useDashboard();
  const [file, setFile] = useState<File | null>(null);
  const [startDate, setStartDate] = useState(new Date());
  const fileInputRef = useRef<HTMLInputElement>(null);

  // Handle file selection
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];
    if (selectedFile) {
      if (
        selectedFile.type === "application/vnd.ms-excel" ||
        selectedFile.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      ) {
        setFile(selectedFile);
      } else {
        alert("Please select a valid Excel file with .xls or .xlsx extension.");
      }
    }
  };

  // Clear selected file
  const handleFileDelete = () => {
    setFile(null);
  };

  // Handle file upload
  const uploadFile = async () => {
    try {
      if (!file) {
        console.error("No file to upload");
        return;
      }

      let response = await dashboardContext?.handleUploadBiometric({
        file,
        date: moment(startDate).format('YYYY-MM-DD'),
      });

      console.log(response, "Upload response");
      if (response?.success) {
        toast.success("File uploaded successfully!");
        handleClose();
        getBioMetricList();
      } else {
        alert("File upload failed!");
      }
    } catch (error) {
      console.error(error, "Upload error");
    }
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Biometric</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div>
          <label>Select date</label>
          <DatePicker
            className='date_picker'
            selected={startDate}
            onChange={(date: any) => setStartDate(date)}
          />

          <label className='mt-4'>Upload file</label>

          {/* Custom styled file input */}
          <div
            className='upload-area'
            onClick={() => fileInputRef.current?.click()}
          >
            <input
              type='file'
              accept='.xls, .xlsx'
              onChange={handleFileChange}
              style={{ display: "none" }} // Hide the input
              ref={fileInputRef} // Reference to the input
            />
            <label className='upload-label' style={{ cursor: "pointer" }}>
              <BsCloudUploadFill fontSize={50} color='burlywood' />
            </label>
            <p>Only Excel formats with .xls or .xlsx extensions.</p>
          </div>

          {/* File list */}
          <div className='file-list'>
            {file && (
              <div key={file.name} className='file-item'>
                <span>{file.name}</span>
                <span className='delete-button' onClick={handleFileDelete}>
                  <RxCrossCircled />
                </span>
              </div>
            )}
          </div>

          <div className='d-flex justify-content-end mt-2'>
            <Button
              className='btn btn-success'
              onClick={uploadFile}
              disabled={!file}
            >
              Upload
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
