import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { useAuthentication } from "../auth/AuthenticationProvider";
import { IoPersonCircle } from "react-icons/io5";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

function AppHeader({
  setSidebarOpen,
  sidebarOpen,
}: {
  setSidebarOpen: Function;
  sidebarOpen: boolean;
}) {
  const authContext = useAuthentication();
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <>
      <div className="header">
        <div className="container-header py-2">
          <div className="logo">Time-sheet</div>
          <div className="d-flex align-items-center">
            <span>
              {authContext?.loggedInUserDetail?.name}
              {" | "}
              {authContext?.loggedInUserDetail?.role.charAt(0).toUpperCase() +
                authContext?.loggedInUserDetail?.role.slice(1)}
            </span>

            <Dropdown align="end">
              <Dropdown.Toggle
                variant="link"
                id="dropdown-profile"
                className="custom-dropdown-toggle"
              >
                {" "}
                <IoPersonCircle
                  style={{ height: "30px", width: "30px" }}
                  className="profile_icon"
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  onClick={() => {
                    authContext?.setActiveSection("Profile");
                    navigate(routes.setting);
                  }}
                >
                  Setting
                </Dropdown.Item>

                <Dropdown.Item
                  onClick={() => {
                    navigate(routes.root);
                    localStorage.clear();
                  }}
                >
                  Logout
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
            <div className=" d-lg-none" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={faBars} fontSize={24} color="#573f8d" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AppHeader;
