import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { useAuthentication } from "../auth/AuthenticationProvider";
import toast from "react-hot-toast";

// Validation schemas for the forms
const ProfileSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  role: Yup.string().required("Required"),
  dob: Yup.date().required("Required"),
  employee_id: Yup.string().required("Required"),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, "Mobile number must be exactly 10 digits")
    .required("Required"),
});

const ChangePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string().required("Current password is required"),
  newPassword: Yup.string()
    .min(6, "Password must be at least 6 characters")
    .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    .matches(/[0-9]/, "Password must contain at least one number")
    .required("New password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwords must match")
    .required("Confirm password is required"),
});

const ProfileAndPassword = () => {
  const authContext = useAuthentication();
  const navigate = useNavigate();
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handlePasswordSubmit = async (values: any) => {
    try {
      const requestBody = {
        password: values.currentPassword,
        newPassword: values.newPassword,
      };
      const response = await authContext?.UpdatePassword(
        authContext?.loggedInUserDetail?.id,
        requestBody
      );
      if (response?.data?.success) {
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      console.error("Error updating password:", error);
    }
  };

  return (
    <div className="profile-and-password-container">
      <div className="profile-section">
        <h2 className="section-title">Personal Info</h2>
        <Formik
          enableReinitialize
          initialValues={{
            name: authContext?.loggedInUserDetail?.name || "",
            email: authContext?.loggedInUserDetail?.email || "",
            role: authContext?.loggedInUserDetail?.role || "",
            dob: authContext?.loggedInUserDetail?.dob || "",
            employee_id: authContext?.loggedInUserDetail?.employee_id || "",
            mobile: authContext?.loggedInUserDetail?.mobile || "",
          }}
          validationSchema={ProfileSchema}
          onSubmit={async (values, { setSubmitting }) => {
            try {
              const response = await authContext?.UpdateProfile(
                authContext?.loggedInUserDetail?.id,
                values
              );
              if (response?.status === 200) {
                toast.success("Profile updated successfully");
              }
            } catch (error) {
              console.error("Error updating profile:", error);
            }
            setSubmitting(false);
          }}
        >
          {({
            handleChange,
            handleBlur,
            values,
            touched,
            errors,
            handleSubmit,
          }) => (
            <Form className="profile-form" noValidate onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label className="profile_label">Name</label>
                  <Field
                    name="name"
                    type="text"
                    className={`form-control ${
                      touched.name && errors.name ? "is-invalid" : ""
                    }`}
                    value={values?.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="name"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
                <div className="form-group">
                  <label className="profile_label">Email</label>
                  <Field
                    name="email"
                    type="email"
                    className="form-control"
                    value={values?.email}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="">
                  <label className="profile_label">Role</label>
                  <Field
                    name="role"
                    type="text"
                    className="form-control"
                    value={values?.role}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label className="profile_label">Date of Birth</label>
                  <Field
                    name="dob"
                    type="date"
                    className="form-control"
                    value={values?.dob}
                    disabled
                  />
                </div>
              </div>

              <div className="form-row">
                <div className="">
                  <label className="profile_label">Employee ID</label>
                  <Field
                    name="employee_id"
                    type="text"
                    className="form-control"
                    value={values?.employee_id}
                    disabled
                  />
                </div>
                <div className="form-group">
                  <label className="profile_label">Mobile</label>
                  <Field
                    name="mobile"
                    type="text"
                    className={`form-control ${
                      touched.mobile && errors.mobile ? "is-invalid" : ""
                    }`}
                    value={values.mobile}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  <ErrorMessage
                    name="mobile"
                    component="div"
                    className="invalid-feedback"
                  />
                </div>
              </div>

              <div className="form-buttons">
                <Button type="submit" className="btn_css p-2">
                  Save
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      <div className="change-password-section">
        <h2 className="section-title">Change Password</h2>
        <div className="change-password-content password-form ">
          <Formik
            initialValues={{
              currentPassword: "",
              newPassword: "",
              confirmPassword: "",
            }}
            validationSchema={ChangePasswordSchema}
            onSubmit={handlePasswordSubmit}
          >
            {({ errors, touched }) => (
              <Form>
                <div className="input-container">
                  <Field
                    type={showCurrentPassword ? "text" : "password"}
                    name="currentPassword"
                    placeholder="Current Password"
                    className="form-control"
                  />
                  <span
                    className="toggle-visibility"
                    onClick={() => setShowCurrentPassword(!showCurrentPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showCurrentPassword ? faEyeSlash : faEye}
                    />
                  </span>
                  <ErrorMessage
                    name="currentPassword"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="input-container">
                  <Field
                    type={showNewPassword ? "text" : "password"}
                    name="newPassword"
                    placeholder="New Password"
                    className="form-control"
                  />
                  <span
                    className="toggle-visibility"
                    onClick={() => setShowNewPassword(!showNewPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showNewPassword ? faEyeSlash : faEye}
                    />
                  </span>
                  <ErrorMessage
                    name="newPassword"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="input-container">
                  <Field
                    type={showConfirmPassword ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm New Password"
                    className="form-control"
                  />
                  <span
                    className="toggle-visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  >
                    <FontAwesomeIcon
                      icon={showConfirmPassword ? faEyeSlash : faEye}
                    />
                  </span>
                  <ErrorMessage
                    name="confirmPassword"
                    component="div"
                    className="error-message"
                  />
                </div>
                <div className="form-buttons">
                  <Button type="submit" className="btn_css p-2">
                    Save
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ProfileAndPassword;
