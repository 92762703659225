// import { Container, Nav, Navbar } from "react-bootstrap";
import ChangePassword from "./ChangePassword";
import Profile from "./Profile";
import { useAuthentication } from "../auth/AuthenticationProvider";
import { useLocation } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
function Setting() {
  const location = useLocation();
  return (
    <AppLayout active={location.pathname}>
      <SettingDetail />
    </AppLayout>
  );
}

export default Setting;

const SettingDetail = () => {
  const context = useAuthentication();

  return (
    <>
      {/* <Navbar expand="lg" className="bg-body-tertiary ">
        <Container fluid>
          <Navbar.Toggle aria-controls="navbarScroll" />
          <Navbar.Collapse id="navbarScroll">
            <Nav
              className="me-auto my-2 my-lg-0"
              style={{ maxHeight: "100px" }}
              navbarScroll
            >
              <Nav.Link
                className={
                  context?.activeSection === "Profile" ? "active_setting" : ""
                }
                onClick={() => context?.setActiveSection("Profile")}
              >
                Profile
              </Nav.Link>
              <Nav.Link
                className={
                  context?.activeSection === "Change Password"
                    ? "active_setting"
                    : ""
                }
                onClick={() => context?.setActiveSection("Change Password")}
              >
                Change Password
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar> */}

      {context?.activeSection === "Profile" && <Profile />}
      {context?.activeSection === "Change Password" && <ChangePassword />}
    </>
  );
};
