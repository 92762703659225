import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import { DashboardProvider, useDashboard } from "./DashboardProvider";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import toast from "react-hot-toast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { useLocation } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import { useAuthentication } from "../auth/AuthenticationProvider";
import { TbDotsVertical } from "react-icons/tb";

const validationSchema = Yup.object().shape({
  userId: Yup.string().required("User ID is required"),
  title: Yup.string().required("Title is required"),
  status: Yup.string().required("Status is required"),
  startDate: Yup.date().required("Start Date is required"),
  endDate: Yup.date().required("End Date is required"),
  dueDate: Yup.date().required("Due Date is required"),
});

function Project() {
  const location = useLocation();
  return (
    <>
      <AppLayout active={location.pathname}>
        <div>
          <DashboardProvider>
            <ProjectDetail />
          </DashboardProvider>
        </div>
      </AppLayout>
    </>
  );
}

export default Project;

const ProjectDetail = () => {
  const dashboardContext = useDashboard();

  const [isActive, setIsActive]: [boolean, Function] = useState(false);
  const [projectList, setProjectList]: [[], Function] = useState([]);
  const [activeBtn, setACtiveBtn]: [string, Function] = useState("");
  const [selectedProject, setSelectedProject] = useState(null);
  const [metaData, setMetaData]: [any, Function] = useState();
  const [page, setPage]: [number, Function] = useState(1);

  const getProjectList = async () => {
    try {
      let response = await dashboardContext?.handleGetProject({
        limit: 10,
        page: page,
      });
      if (response?.success) {
        setProjectList(response?.projects);
        setMetaData(response?.metaData);
      }
    } catch (error) {}
  };

  const columns = [
    {
      id: "title",
      name: "Title",
      selector: (row: any) => row.title,
    },

    {
      id: "status",
      name: "Status",
      selector: (row: any) => row.status,
    },
    {
      id: "startDate",
      name: "Start date",
      selector: (row: any) => moment(row.startDate).format("YYYY-MM-DD"),
    },
    {
      id: "endDate",
      name: "End Date",
      selector: (row: any) => moment(row.endDate).format("YYYY-MM-DD"),
    },

    {
      id: "dueDate",
      name: "Actual Closure",
      selector: (row: any) => moment(row.dueDate).format("YYYY-MM-DD"),
    },

    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <Dropdown className="view">
            <Dropdown.Toggle id="dropdown-basic" className="view_dropdown">
              <TbDotsVertical color="black" fontSize={21} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  setIsActive(true);
                  setACtiveBtn("edit");
                  setSelectedProject(row);
                }}
              >
                Edit
              </Dropdown.Item>
              <Dropdown.Item
                onClick={async () => {
                  try {
                    let response = await dashboardContext?.handleDeleteProject(
                      row?.id
                    );

                    if (response?.success) {
                      getProjectList();
                      toast.success(response?.message);
                    }
                  } catch (error) {}
                }}
              >
                Delete
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        );
      },
    },
  ];
  useEffect(() => {
    getProjectList();
  }, [page]);
  return (
    <>
      <div className="emp_list">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className=" fw-semibold ">Project</h6>
          <Button
            className="btn-sm d-flex align-items-center gap-2 py-2 btn btn-primary btn btn-primary"
            onClick={() => {
              setIsActive(true);
              setACtiveBtn("add");
              setSelectedProject(null);
            }}
          >
            Add Project
          </Button>
        </div>
        <DataTable
          className="data-table"
          columns={columns}
          data={projectList}
          highlightOnHover
          progressPending={!projectList}
          paginationPerPage={metaData?.limit}
          paginationTotalRows={metaData?.total_count}
          paginationDefaultPage={1}
          pagination
          paginationServer
          onChangePage={(page) => {
            setPage(page);
          }}
          paginationComponentOptions={{
            noRowsPerPage: true,
          }}
        />

        {isActive && (
          <CreateProjectModal
            show={isActive}
            handleClose={() => setIsActive(false)}
            getProjectList={getProjectList}
            activeBtn={activeBtn}
            projectSheet={selectedProject}
          />
        )}
      </div>
    </>
  );
};

function CreateProjectModal({
  show,
  handleClose,
  projectSheet,
  getProjectList,
  activeBtn,
}: any) {
  const dashboardContext = useDashboard();
  const authContext = useAuthentication();

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          {activeBtn === "edit" ? "Update Project Detail" : "Create Project"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={
            projectSheet
              ? {
                  userId: projectSheet?.userId,
                  title: projectSheet?.title,
                  status: projectSheet?.status,
                  startDate: moment(projectSheet?.startDate).format(
                    "YYYY-MM-DD"
                  ),
                  endDate: moment(projectSheet?.endDate).format("YYYY-MM-DD"),
                  dueDate: moment(projectSheet?.dueDate).format("YYYY-MM-DD"),
                }
              : {
                  userId: authContext?.loggedInUserDetail?.id,
                  title: "",
                  status: "",
                  startDate: "",
                  endDate: "",
                  dueDate: "",
                }
          }
          validationSchema={validationSchema}
          onSubmit={async (values, { setSubmitting }: any) => {
            try {
              let response = await (activeBtn === "edit"
                ? dashboardContext?.updateProject(projectSheet?.id, values)
                : dashboardContext?.createProject(values));

              if (
                activeBtn === "edit"
                  ? response?.data?.success
                  : response?.success
              ) {
                if (activeBtn === "edit") {
                  toast.success("Project details updated..");
                } else {
                  toast.success("New project created..");
                }
                getProjectList();
                handleClose();
              }
            } catch (error) {
              console.error(error);
            }
            setSubmitting(false);
            handleClose();
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            values,
            touched,
            isValid,
            errors,
            isSubmitting,
          }) => (
            <Form noValidate onSubmit={handleSubmit}>
              {/* <Form.Group controlId="formUserId" className="mt-3">
                <Form.Label>
                  User ID<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter User ID"
                  name="userId"
                  value={values.userId}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled
                  isInvalid={touched.userId && !!errors.userId}
                />
              </Form.Group> */}

              <Form.Group controlId="formTitle">
                <Form.Label>
                  Title<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter title"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.title && !!errors.title}
                />
              </Form.Group>

              <Form.Group controlId="formStatus" className="mt-3">
                <Form.Label>
                  Status<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter status"
                  name="status"
                  value={values.status}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.status && !!errors.status}
                />
              </Form.Group>

              <Form.Group controlId="formStartDate" className="mt-3">
                <Form.Label>
                  Start Date<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="startDate"
                  value={values.startDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.startDate && !!errors.startDate}
                />
              </Form.Group>

              <Form.Group controlId="formEndDate" className="mt-3">
                <Form.Label>
                  End Date<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="endDate"
                  value={values.endDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.endDate && !!errors.endDate}
                />
              </Form.Group>

              <Form.Group controlId="formDueDate" className="mt-3">
                <Form.Label>
                  Actual Closure<span className="mendatory">*</span>
                </Form.Label>
                <Form.Control
                  type="date"
                  name="dueDate"
                  value={values.dueDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.dueDate && !!errors.dueDate}
                />
              </Form.Group>

              <Button
                variant="primary"
                type="submit"
                className="mt-3"
                disabled={isSubmitting}
              >
                {activeBtn === "edit" ? "Update" : "Submit"}
              </Button>
            </Form>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}
