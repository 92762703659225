import { useLocation } from "react-router-dom";
import AppLayout from "../layouts/AppLayout";
import { DashboardProvider } from "../Dashboard/DashboardProvider";
import { Tree, TreeNode } from "react-organizational-chart";

const OrgChart = () => {
  const location = useLocation();

  return (
    <AppLayout active={location.pathname}>
      <div>
        <DashboardProvider>
          <Org />
        </DashboardProvider>
      </div>
    </AppLayout>
  );
};

const Org = () => {
  return (
    <div className="chart-container ">
      <Tree label={<div className="root-node">JaiKeerthi K.(CEO)</div>}>
        <TreeNode label={<div className="node">Panneerselvam(PM)</div>}>
          <TreeNode label={<div className="node">Nishanth(Developer)</div>}>
            <TreeNode
              label={<div className="node">Muhammed Usama(Developer)</div>}
            >
              {" "}
              <TreeNode
                label={<div className="node">Tamil Selvan S(Developer)</div>}
              >
                {" "}
                <TreeNode
                  label={<div className="node">Kajaa Afridee(Developer)</div>}
                >
                  {" "}
                  <TreeNode
                    label={<div className="node">Tom George(Tester)</div>}
                  >
                    {" "}
                    <TreeNode
                      label={
                        <div className="node">Nithish Natarajan(Developer)</div>
                      }
                    ></TreeNode>
                  </TreeNode>
                </TreeNode>
              </TreeNode>
            </TreeNode>
          </TreeNode>
        </TreeNode>
        <TreeNode label={<div className="node">Rachana Sarikonda(PM)</div>}>
          <TreeNode label={<div className="node">Sangeeth.Ka(Developer)</div>}>
            <TreeNode
              label={<div className="node">Prajwal Kumar(Developer)</div>}
            >
              {" "}
              <TreeNode
                label={
                  <div className="node">Ayesha Feroz Ahmad(Developer)</div>
                }
              >
                {" "}
                <TreeNode
                  label={<div className="node">Suhail Khan(Developer)</div>}
                >
                  {" "}
                  <TreeNode
                    label={
                      <div className="node">Pradeep Vadivel(Developer)</div>
                    }
                  >
                    {" "}
                    <TreeNode
                      label={<div className="node">Chitra R(Tester)</div>}
                    ></TreeNode>
                  </TreeNode>
                </TreeNode>
              </TreeNode>
            </TreeNode>
          </TreeNode>
        </TreeNode>
        <TreeNode label={<div className="node">Tejesh(PM)</div>}>
          <TreeNode
            label={<div className="node">Dinesh Kumar D(Developer)</div>}
          >
            <TreeNode label={<div className="node">Bavatharani(Designer)</div>}>
              {" "}
              <TreeNode
                label={<div className="node">Dinesh Kumar R(Developer)</div>}
              >
                {" "}
                <TreeNode
                  label={
                    <div className="node">
                      Srikrishna Chaitanya Pabbu(Tester)
                    </div>
                  }
                >
                  {" "}
                  <TreeNode
                    label={
                      <div className="node">Mohammed Sameer(Developer)</div>
                    }
                  >
                    {" "}
                    <TreeNode
                      label={
                        <div className="node">Mohammed Askar(Developer)</div>
                      }
                    ></TreeNode>
                  </TreeNode>
                </TreeNode>
              </TreeNode>
            </TreeNode>
          </TreeNode>
        </TreeNode>
        <TreeNode
          label={<div className="node">Manoj Kumar B(Finance Manager)</div>}
        ></TreeNode>
        <TreeNode
          label={<div className="node">Pawan Kumar(HR)</div>}
        ></TreeNode>
      </Tree>
    </div>
  );
};

export default OrgChart;
