export const routes = {
  root: "/",
  signIn: "/login",
  employeeList: "/employee",
  timesheet: "/timesheet",
  project: "/projects",
  leave: "/leave",
  wfh: "/wfh",
  setting: "/settings",
  calender: "/calender",
  org: "/organisation",
  bioMetric: "/biometric",
};
