import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./utils/routes";
import SignInForm from "./components/auth/SignInForm";
import EmployeeList from "./components/Dashboard/EmployeeList";
import TimeSheet from "./components/Dashboard/TimeSheet";
import Project from "./components/Dashboard/Projects";
import Leave from "./components/Dashboard/Leave";
import Wfh from "./components/Dashboard/Wfh";
import Setting from "./components/Setting/Setting";
import OrgChart from "./components/Setting/OrgChart";
import BioMetric from "./components/Setting/BioMetric";
import Calender from "./components/Setting/Calender";

function Layout({ setIsExtension }: { setIsExtension: Function }) {
  return (
    <div className="App">
      <Router>
        <div className="app">
          <div className="content">
            <Routes>
              <Route
                path={routes.root}
                element={<SignInForm setIsExtension={setIsExtension} />}
              />
              <Route path={routes.employeeList} element={<EmployeeList />} />
              <Route path={routes.timesheet} element={<TimeSheet />} />
              <Route path={routes.project} element={<Project />} />
              <Route path={routes.leave} element={<Leave />} />
              <Route path={routes.wfh} element={<Wfh />} />
              <Route path={routes.setting} element={<Setting />} />
              <Route path={routes.calender} element={<Calender />} />
              <Route path={routes.org} element={<OrgChart />} />
              <Route path={routes.bioMetric} element={<BioMetric />} />
            </Routes>
          </div>
        </div>
      </Router>
    </div>
  );
}

export default Layout;
