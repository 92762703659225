import { ReactNode, useEffect, useState } from "react";
import { Link, matchPath, useNavigate } from "react-router-dom";
import { routes } from "../../utils/routes";
import { AuthenticationProvider } from "../auth/AuthenticationProvider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { GrProjects } from "react-icons/gr";
import AppHeader from "./AppHeader";
import { MdCoPresent, MdOutlineAddHomeWork } from "react-icons/md";
import "./AppLayout.css";
import { IoPeopleSharp, IoSettings } from "react-icons/io5";
import { BsCalendar2DateFill, BsPersonArmsUp } from "react-icons/bs";
import { LiaBusinessTimeSolid } from "react-icons/lia";
import { TbHierarchy3 } from "react-icons/tb";

interface AppLayoutProps {
  children: ReactNode;
  active: string;
}

const AppLayout = ({ children, active }: AppLayoutProps) => {
  const navigate = useNavigate();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("auth-token");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  return (
    <AuthenticationProvider>
      <div className="d-flex position-relative">
        <AppHeader setSidebarOpen={setSidebarOpen} sidebarOpen={sidebarOpen} />
        <AppSideBar active={active} sidebarOpen={sidebarOpen} />
        <div className="flex-grow-1 app-children px-3">{children}</div>
      </div>
    </AuthenticationProvider>
  );
};

export default AppLayout;

const AppSideBar = ({ active, sidebarOpen }: any) => {
  return (
    <>
      {sidebarOpen && (
        <ResponsiveSidebar active={active} sidebarOpen={sidebarOpen} />
      )}

      <div className="sidebar d-flex flex-column justify-content-between d-none d-lg-flex">
        <div>
          <nav>
            <ul>
              {localStorage.getItem("role") === "admin" && (
                <li>
                  <Link
                    to={routes.employeeList}
                    className={`menu-item text-decoration-none mb-3 ${
                      matchPath(active, routes.employeeList) ? "nav-active" : ""
                    }`}
                  >
                    <IoPeopleSharp className="mb-1" />
                    &nbsp; Employees
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={routes.leave}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.leave) ? "nav-active" : ""
                  }`}
                >
                  <BsPersonArmsUp className="mb-1" />
                  &nbsp; Leave
                </Link>
              </li>
              <li>
                <Link
                  to={routes.wfh}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.wfh) ? "nav-active" : ""
                  }`}
                >
                  <MdOutlineAddHomeWork className="mb-1" /> &nbsp; Work From
                  Home
                </Link>
              </li>
              {localStorage.getItem("role") === "admin" && (
                <li>
                  <Link
                    to={routes.project}
                    className={`menu-item text-decoration-none mb-3 ${
                      matchPath(active, routes.project) ? "nav-active" : ""
                    }`}
                  >
                    <GrProjects className="mb-1" />
                    &nbsp; Projects
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={routes.timesheet}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.timesheet) ? "nav-active" : ""
                  }`}
                >
                  <LiaBusinessTimeSolid className="mb-1" /> &nbsp; Timesheet
                </Link>
              </li>
              <li>
                <Link
                  to={routes.calender}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.calender) ? "nav-active" : ""
                  }`}
                >
                  <BsCalendar2DateFill className="mb-1" />
                  &nbsp; Calender
                </Link>
              </li>
              <li>
                <Link
                  to={routes.bioMetric}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.bioMetric) ? "nav-active" : ""
                  }`}
                >
                  <MdCoPresent className="mb-1" />
                  &nbsp; Biometric
                </Link>
              </li>
              <li>
                <Link
                  to={routes.org}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.org) ? "nav-active" : ""
                  }`}
                >
                  <TbHierarchy3 className="mb-1" />
                  &nbsp; Hierarchy
                </Link>
              </li>
              <li>
                <Link
                  to={routes.setting}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.setting) ? "nav-active" : ""
                  }`}
                >
                  <IoSettings className="mb-1" />
                  &nbsp; Setting
                </Link>
              </li>
            </ul>
          </nav>
        </div>
        <div className="sidebar-footer mt-auto">
          <nav>
            <ul>
              <li>
                <Link
                  to={routes.root}
                  className="logout nav-active text-center"
                  onClick={() => {
                    localStorage.clear();
                  }}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  &nbsp; &nbsp; Logout
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};

const ResponsiveSidebar = ({ active, sidebarOpen }: any) => {
  return (
    <>
      <div
        className={`responsive-sidebar d-flex flex-column justify-content-between ${
          sidebarOpen ? "active" : ""
        }`}
      >
        <div className="logo_responsive_sidebar">Time-sheet</div>

        <div>
          <nav>
            <ul>
              {localStorage.getItem("role") === "admin" && (
                <li>
                  <Link
                    to={routes.employeeList}
                    className={`menu-item text-decoration-none mb-3 ${
                      matchPath(active, routes.employeeList) ? "nav-active" : ""
                    }`}
                  >
                    <IoPeopleSharp className="mb-1" />
                    &nbsp; Employees
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={routes.leave}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.leave) ? "nav-active" : ""
                  }`}
                >
                  <BsPersonArmsUp className="mb-1" />
                  &nbsp; Leave
                </Link>
              </li>
              <li>
                <Link
                  to={routes.wfh}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.wfh) ? "nav-active" : ""
                  }`}
                >
                  <MdOutlineAddHomeWork className="mb-1" /> &nbsp; Work From
                  Home
                </Link>
              </li>
              {localStorage.getItem("role") === "admin" && (
                <li>
                  <Link
                    to={routes.project}
                    className={`menu-item text-decoration-none mb-3 ${
                      matchPath(active, routes.project) ? "nav-active" : ""
                    }`}
                  >
                    <GrProjects className="mb-1" />
                    &nbsp; Projects
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={routes.timesheet}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.timesheet) ? "nav-active" : ""
                  }`}
                >
                  <LiaBusinessTimeSolid className="mb-1" /> &nbsp; Timesheet
                </Link>
              </li>
              <li>
                <Link
                  to={routes.calender}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.calender) ? "nav-active" : ""
                  }`}
                >
                  <BsCalendar2DateFill className="mb-1" />
                  &nbsp; Calender
                </Link>
              </li>
              <li>
                <Link
                  to={routes.bioMetric}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.bioMetric) ? "nav-active" : ""
                  }`}
                >
                  <MdCoPresent className="mb-1" />
                  &nbsp; Biometric
                </Link>
              </li>
              <li>
                <Link
                  to={routes.org}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.org) ? "nav-active" : ""
                  }`}
                >
                  <TbHierarchy3 className="mb-1" />
                  &nbsp; Hierarchy
                </Link>
              </li>
              <li>
                <Link
                  to={routes.setting}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.setting) ? "nav-active" : ""
                  }`}
                >
                  <IoSettings className="mb-1" />
                  &nbsp; Setting
                </Link>
              </li>
            </ul>
          </nav>
          {/* <nav>
            <ul>
              {localStorage.getItem("role") === "admin" && (
                <li>
                  <Link
                    to={routes.employeeList}
                    className={`menu-item text-decoration-none mb-3 ${
                      matchPath(active, routes.employeeList) ? "nav-active" : ""
                    }`}
                  >
                    Employees
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={routes.leave}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.leave) ? "nav-active" : ""
                  }`}
                >
                  Leave
                </Link>
              </li>
              <li>
                <Link
                  to={routes.wfh}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.wfh) ? "nav-active" : ""
                  }`}
                >
                  Work From Home
                </Link>
              </li>
              <li>
                <Link
                  to={routes.wfh}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.wfh) ? "nav-active" : ""
                  }`}
                >
                  Biometric
                </Link>
              </li>
              {localStorage.getItem("role") === "admin" && (
                <li>
                  <Link
                    to={routes.project}
                    className={`menu-item text-decoration-none mb-3 ${
                      matchPath(active, routes.project) ? "nav-active" : ""
                    }`}
                  >
                    Projects
                  </Link>
                </li>
              )}
              <li>
                <Link
                  to={routes.timesheet}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.timesheet) ? "nav-active" : ""
                  }`}
                >
                  Timesheet
                </Link>
              </li>
              <li>
                <Link
                  to={routes.calender}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.calender) ? "nav-active" : ""
                  }`}
                >
                  Calender
                </Link>
              </li>
              <li>
                <Link
                  to={routes.org}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.org) ? "nav-active" : ""
                  }`}
                >
                  Hierarchy
                </Link>
              </li>
              <li>
                <Link
                  to={routes.setting}
                  className={`menu-item text-decoration-none mb-3 ${
                    matchPath(active, routes.setting) ? "nav-active" : ""
                  }`}
                >
                  Setting
                </Link>
              </li>
            </ul>
          </nav> */}
        </div>
        <div className="sidebar-footer mt-auto">
          <nav>
            <ul>
              <li>
                <Link
                  to={routes.root}
                  className="logout nav-active text-center"
                  onClick={() => {
                    localStorage.clear();
                  }}
                >
                  <FontAwesomeIcon icon={faSignOutAlt} />
                  &nbsp; &nbsp; Logout
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
};
