import { Button, Dropdown, Form, Modal } from "react-bootstrap";
import AppLayout from "../layouts/AppLayout";
import { DashboardProvider, useDashboard } from "./DashboardProvider";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import * as Yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import DataTable from "react-data-table-component";
import { useAuthentication } from "../auth/AuthenticationProvider";
import { useLocation } from "react-router-dom";
import Select from "react-select";
import { TbDotsVertical } from "react-icons/tb";
import { DateRangePicker } from "react-date-range";

const validationSchema = Yup.object().shape({
  employee_id: Yup.string().required("Employee ID is required"),
  leave_type: Yup.string().required("Leave type is required"),
  startDate: Yup.string().required("Start date is required"),
  endDate: Yup.string().required("End date is required"),
  reason: Yup.string().required("Reason is required"),
});

function Leave() {
  const location = useLocation();
  return (
    <>
      <AppLayout active={location.pathname}>
        <div>
          <DashboardProvider>
            <LeaveSection />
          </DashboardProvider>
        </div>
      </AppLayout>
    </>
  );
}

export default Leave;

const LeaveSection = () => {
  const dashboardContext = useDashboard();
  const authContext = useAuthentication();

  const [isActive, setIsActive]: [boolean, Function] = useState(false);
  const [leaveList, setLeaveList]: [any, Function] = useState();
  const [activeBtn, setACtiveBtn]: [string, Function] = useState("");
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [leaveID, setLeaveId]: [string, Function] = useState("");
  const [metaData, setMetaData]: [any, Function] = useState();
  const [page, setPage]: [number, Function] = useState(1);

  const columns = [
    {
      id: "employee_id",
      name: "Employee Name",
      selector: (row: any) => row.employee?.name,
    },

    {
      id: "startDate",
      name: "Start date",
      selector: (row: any) => row.startDate,
    },
    {
      id: "endDate",
      name: "End Date",
      selector: (row: any) => row.endDate,
    },

    {
      id: "status",
      name: "Status",

      cell: (row: any) => {
        return (
          <div
            className={` ${
              row?.status === "rejected"
                ? "rejected-badge"
                : row?.status === "approved"
                ? "accept-badge"
                : "draft-badge"
            }`}
          >
            {row.status === "rejected"
              ? "Rejected"
              : row?.status === "approved"
              ? "Approved"
              : row?.status === "requested"
              ? "Requested"
              : row?.status}
          </div>
        );
      },
    },
    {
      name: "Actions",
      width: "130px",
      grow: 0,
      reorder: true,
      sortable: true,
      cell: (row: any) => {
        return (
          <>
            {authContext?.activeEmpRole === "admin" ? (
              <>
                <Dropdown className="view">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="view_dropdown"
                  >
                    <TbDotsVertical color="black" fontSize={21} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item
                      disabled={row?.status !== "requested"}
                      onClick={async () => {
                        setIsActive(true);
                        setACtiveBtn("update");
                        setLeaveId(row?._id);
                        setSelectedLeave(row);
                      }}
                    >
                      View
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            ) : (
              <>
                <Dropdown className="view">
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    className="view_dropdown"
                  >
                    <TbDotsVertical color="black" fontSize={18} />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="dropdown_menu_item">
                    <Dropdown.Item
                      disabled={row?.status !== "requested"}
                      onClick={() => {
                        setIsActive(true);
                        setACtiveBtn("edit");
                        setSelectedLeave(row);
                      }}
                    >
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item
                      disabled={row?.status !== "requested"}
                      onClick={async () => {
                        let response =
                          await dashboardContext?.handleDeleteLeave(row?.id);
                        if (response?.success) {
                          toast.success("Timesheet deleted successfully");
                          getLeaveDetail();
                        } else {
                          toast.error(response?.message);
                        }
                      }}
                    >
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </>
        );
      },
    },
  ];

  const getLeaveDetail = async () => {
    try {
      let requestBody = {
        limit: 10,
        page: page,
      };
      let response = await dashboardContext?.handleGetLeaveDetail(requestBody);
      if (response?.success) {
        setLeaveList(response?.leaves);
        setMetaData(response?.meta);
      }
    } catch (error) {}
  };
  useEffect(() => {
    getLeaveDetail();
  }, [page]);

  return (
    <>
      <div className="emp_list">
        <div className="d-flex justify-content-between align-items-center">
          <h6 className=" fw-semibold ">Leave</h6>
          {localStorage.getItem("role") !== "admin" && (
            <Button
              className="btn-sm d-flex align-items-center gap-2 py-2 btn btn-primary btn btn-primary"
              onClick={() => {
                setIsActive(true);
                setACtiveBtn("add");
                setSelectedLeave(null);
              }}
            >
              Add Leave
            </Button>
          )}
        </div>

        <DataTable
          className="data-table"
          columns={columns}
          data={leaveList}
          highlightOnHover
          progressPending={!leaveList}
          paginationPerPage={metaData?.limit}
          paginationTotalRows={metaData?.total_count}
          paginationDefaultPage={1}
          pagination
          paginationServer
          onChangePage={(page) => {
            setPage(page);
          }}
          paginationComponentOptions={{
            noRowsPerPage: true,
            // disableNext: isLastPage(),
          }}
        />

        {isActive && (
          <CreateTimeSheetModal
            show={isActive}
            handleClose={() => setIsActive(false)}
            getLeaveDetail={getLeaveDetail}
            activeBtn={activeBtn}
            leave={selectedLeave}
            leaveID={leaveID}
          />
        )}
      </div>
    </>
  );
};

function CreateTimeSheetModal({
  show,
  handleClose,
  getLeaveDetail,
  activeBtn,
  leave,
  leaveID,
}: any) {
  const dashboardContext = useDashboard();
  const authContext = useAuthentication();
  const leaveTypeOptions = [
    { value: "sick", label: "Sick" },
    { value: "casual", label: "Casual" },
  ];

  const [state, setState] = useState({
    selection: {
      startDate: new Date(leave?.startDate),
      endDate: new Date(leave?.endDate),
      key: "selection",
    },
  });

  const renderDayContent = (day: any) => {
    return <div>{day.getDate()}</div>;
  };

  const isDayDisabled = (day: any) => true;

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            {activeBtn === "edit"
              ? "Update leave detail"
              : activeBtn === "update"
              ? "Update leave"
              : "Apply leave"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {authContext?.activeEmpRole === "admin" ? (
            <>
              <div>
                <label>Employee Name</label>
              </div>
              <div className="reason-text mb-3"> {leave?.employee?.name}</div>
              <div className="date-range">
                <DateRangePicker
                  ranges={[state.selection]}
                  dayContentRenderer={renderDayContent}
                  disabledDay={isDayDisabled}
                />
              </div>
              <div>
                <label>Reason</label>
              </div>
              <div className="reason-text"> {leave?.reason}</div>

              <div className="button-container mt-3 d-flex justify-content-end">
                <Button
                  className="btn-accept"
                  onClick={async () => {
                    try {
                      let response =
                        await dashboardContext?.handleAcceptAndReject({
                          leaveId: leaveID,
                          status: "approved",
                        });
                      if (response) {
                        getLeaveDetail();
                        toast?.success("Leave approved successfully");
                        handleClose();
                      }
                    } catch (error) {}
                  }}
                >
                  Accept
                </Button>
                <Button
                  className="btn-reject"
                  onClick={async () => {
                    try {
                      let response =
                        await dashboardContext?.handleAcceptAndReject({
                          leaveId: leaveID,
                          status: "rejected",
                        });
                      if (response) {
                        getLeaveDetail();
                        toast?.error("Leave rejected!");
                        handleClose();
                      }
                    } catch (error) {}
                  }}
                >
                  Reject
                </Button>
              </div>
            </>
          ) : (
            <Formik
              initialValues={
                leave
                  ? {
                      employee_id: leave?.employee?.name,
                      startDate: leave?.startDate,
                      endDate: leave?.endDate,
                      reason: leave?.reason,
                      leave_type: leave?.leave_type,
                    }
                  : {
                      employee_id: authContext?.loggedInUserDetail?.name,
                      startDate: "",
                      endDate: "",
                      reason: "",
                      leave_type: "",
                    }
              }
              validationSchema={validationSchema}
              onSubmit={async (values, { setSubmitting }) => {
                try {
                  let requestBody = {
                    employee_id: authContext?.loggedInUserDetail?.id,
                    startDate: values.startDate,
                    endDate: values.endDate,
                    reason: values.reason,
                    leave_type: values.leave_type,
                  };
                  let response = await (activeBtn === "edit"
                    ? dashboardContext?.handleUpdateLeave(
                        leave?.id,
                        requestBody
                      )
                    : dashboardContext?.handleCreateLeave(requestBody));

                  if (
                    activeBtn === "edit"
                      ? response?.data?.success
                      : response?.success
                  ) {
                    getLeaveDetail();
                    handleClose();
                    toast.success(
                      activeBtn === "edit"
                        ? "Leave updated successfully."
                        : "Leave created successfully."
                    );
                  }
                } catch (error) {
                  console.error(error);
                }
                setSubmitting(false);
                handleClose();
              }}
            >
              {({
                handleSubmit,
                handleChange,
                handleBlur,
                values,
                touched,
                isValid,
                errors,
                isSubmitting,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group controlId="formEmployeeId" className="mt-3">
                    <Form.Label>
                      Employee Name<span className="mendatory">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter employee ID"
                      name="employee_id"
                      value={values.employee_id}
                      disabled
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.employee_id && !!errors.employee_id}
                    />
                    <Form.Control.Feedback type="invalid">
                      {(Array.isArray(errors.employee_id) &&
                        errors.employee_id.join(", ")) ||
                        "Employee ID is required"}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formLeaveType" className="mt-3">
                    <Form.Label>
                      Leave Type<span className="mendatory">*</span>
                    </Form.Label>
                    <Select
                      name="leave_type"
                      value={leaveTypeOptions.find(
                        (option) => option.value === values?.leave_type
                      )}
                      options={leaveTypeOptions}
                      onChange={(option) =>
                        setFieldValue("leave_type", option?.value)
                      }
                      onBlur={handleBlur}
                      // isInvalid={touched.leave_type && !!errors?.leave_type}
                    />
                    {/* {touched.leave_type && !!errors.leave_type && (
                  <div className="invalid-feedback d-block">
                    {errors.leave_type}
                  </div>
                )} */}
                  </Form.Group>

                  <Form.Group controlId="formStartDate" className="mt-3">
                    <Form.Label>
                      Start Date<span className="mendatory">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="startDate"
                      value={values.startDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.startDate && !!errors.startDate}
                    />
                    <Form.Control.Feedback type="invalid">
                      {(Array.isArray(errors.startDate) &&
                        errors.startDate.join(", ")) ||
                        "Start date is required"}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formEndDate" className="mt-3">
                    <Form.Label>
                      End Date<span className="mendatory">*</span>
                    </Form.Label>
                    <Form.Control
                      type="date"
                      name="endDate"
                      value={values.endDate}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.endDate && !!errors.endDate}
                    />

                    <Form.Control.Feedback type="invalid">
                      {(Array.isArray(errors.endDate) &&
                        errors.endDate.join(", ")) ||
                        "End date is required"}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="formReason" className="mt-3">
                    <Form.Label>
                      Reason<span className="mendatory">*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      placeholder="Enter reason"
                      name="reason"
                      value={values.reason}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={touched.reason && !!errors.reason}
                    />

                    <Form.Control.Feedback type="invalid">
                      {(Array.isArray(errors.reason) &&
                        errors.reason.join(", ")) ||
                        "Reason is required"}
                    </Form.Control.Feedback>
                  </Form.Group>
                  {activeBtn !== "update" ? (
                    <Button
                      variant="primary"
                      type="submit"
                      className="mt-3"
                      disabled={isSubmitting}
                    >
                      {activeBtn === "edit" ? "Update" : "Submit"}
                    </Button>
                  ) : (
                    <div className="button-container mt-3">
                      <Button
                        className="btn-accept"
                        onClick={async () => {
                          try {
                            let response =
                              await dashboardContext?.handleAcceptAndReject({
                                leaveId: leaveID,
                                status: "approved",
                              });
                            if (response) {
                              getLeaveDetail();
                              toast?.success("Leave approved successfully");
                              handleClose();
                            }
                          } catch (error) {}
                        }}
                      >
                        Accept
                      </Button>
                      <Button
                        className="btn-reject"
                        onClick={async () => {
                          try {
                            let response =
                              await dashboardContext?.handleAcceptAndReject({
                                leaveId: leaveID,
                                status: "rejected",
                              });
                            if (response) {
                              getLeaveDetail();
                              toast?.error("Leave rejected!");
                              handleClose();
                            }
                          } catch (error) {}
                        }}
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
