import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { api } from "../../utils/api";

export interface AuthenticationContextInterface {
  handleSignIn: Function;
  UpdatePassword: Function;
  loggedInUserDetail: any;
  UpdateProfile: Function;
  activeEmpRole: String;
  setActiveSection: Function;
  activeSection: string;
  handleGetUser: Function;
}

const AuthContext = React.createContext<AuthenticationContextInterface | null>(
  null
);

type AuthenticationProviderProps = {
  children?: ReactNode;
};
const AuthenticationProvider = ({ children }: AuthenticationProviderProps) => {
  const [loggedInUserDetail, setLoggedInUserDeatil]: [any, Function] =
    useState();
  const [activeEmpRole, setEmpActiveRole]: [string, Function] = useState("");
  const [activeSection, setActiveSection]: [string, Function] =
    useState("Profile");

  const handleSignIn = async (params: any) => {
    try {
      let response = await api?.signIn(params);

      return response;
    } catch (error) {}
  };

  const UpdatePassword = async (id: string, params: any) => {
    try {
      let response = await api?.updatePassword(id, params);
      return response;
    } catch (error) {}
  };

  const handleGetUser = async () => {
    try {
      let response = await api?.getUser();

      if (response?.success) {
        setLoggedInUserDeatil(response?.user);
        setEmpActiveRole(response?.user?.role);
        localStorage.setItem("role", response?.user?.role);
      }
    } catch (error) {}
  };

  const UpdateProfile = async (id: any, params: any) => {
    try {
      let response = await api?.updateProfile(id, params);
      return response;
    } catch (error) {}
  };

  useEffect(() => {
    handleGetUser();
  }, []);

  const value = useMemo(
    () => ({
      handleSignIn,
      UpdatePassword,
      loggedInUserDetail,
      UpdateProfile,
      activeEmpRole,
      setActiveSection,
      activeSection,
      handleGetUser,
    }),
    [loggedInUserDetail, activeSection, activeEmpRole]
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const useAuthentication = () => {
  return React.useContext(AuthContext);
};

export { AuthenticationProvider, useAuthentication };
