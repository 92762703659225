import React, { ReactNode, useEffect, useMemo, useState } from "react";
import { api } from "../../utils/api";
import { useLocation } from "react-router-dom";

export interface DashboardContextInterface {
  createEmployeeList: Function;
  handleGetEmployee: Function;
  createTimesheetList: Function;
  handleGetTimesheet: Function;
  handleDeleteTimeSheet: Function;
  updateTimesheet: Function;
  createProject: Function;
  handleGetProject: Function;
  handleDeleteProject: Function;
  updateProject: Function;
  getTimeSheetById: Function;
  handleCreateLeave: Function;
  handleGetLeaveDetail: Function;
  handleUpdateLeave: Function;
  handleDeleteLeave: Function;
  handleDeleteWfh: Function;
  handleWfh: Function;
  handleGetWfhDetail: Function;
  handleUpdateWfh: Function;
  handleAcceptAndReject: Function;
  handleAcceptAndRejectWfh: Function;
  handleUploadBiometric: Function;
  getBiometricList: Function;
  projectList: [];
}

const DashboardContext = React.createContext<DashboardContextInterface | null>(
  null
);

type DashboardProviderProps = {
  children?: ReactNode;
};
const DashboardProvider = ({ children }: DashboardProviderProps) => {
  const location = useLocation();

  const [projectList, setProjectList]: [[], Function] = useState([]);

  const createEmployeeList = async (params: any) => {
    try {
      let response = await api?.createEmployee(params);
      return response;
    } catch (error) {}
  };

  const handleGetEmployee = async (params: any) => {
    try {
      let response = await api?.getEmployee(params);
      return response;
    } catch (error) {}
  };

  const createTimesheetList = async (params: any) => {
    try {
      let response = await api?.createTimeSheet(params);
      return response;
    } catch (error) {}
  };

  const handleGetTimesheet = async (params: any) => {
    try {
      let response = await api?.getTimesheet(params);
      return response;
    } catch (error) {}
  };

  const handleDeleteTimeSheet = async (id: any) => {
    try {
      let response = await api?.deleteTimesheet(id);
      return response;
    } catch (error) {}
  };
  const updateTimesheet = async (id: any, params: any) => {
    try {
      let response = await api?.updateTimeSheet(id, params);
      return response;
    } catch (error) {}
  };

  const getTimeSheetById = async (id: string) => {
    try {
      let response = await api?.getTimesheetById(id);
      return response;
    } catch (error) {}
  };

  //   ..................project
  const createProject = async (params: any) => {
    try {
      let response = await api?.createProject(params);
      return response;
    } catch (error) {}
  };

  const handleGetProject = async (params: any) => {
    try {
      let response = await api?.getProject(params);
      if (response?.success) {
        setProjectList(response?.projects);
      }

      return response;
    } catch (error) {}
  };

  const handleDeleteProject = async (id: any) => {
    try {
      let response = await api?.deleteProject(id);
      return response;
    } catch (error) {}
  };
  const updateProject = async (id: any, params: any) => {
    try {
      let response = await api?.updateProject(id, params);
      return response;
    } catch (error) {}
  };

  // ........................leave...............

  const handleCreateLeave = async (params: any) => {
    try {
      let response = await api?.createLeave(params);
      return response;
    } catch (error) {}
  };

  const handleGetLeaveDetail = async (params: any) => {
    try {
      let response = await api?.getLeave(params);
      return response;
    } catch (error) {}
  };

  const handleUpdateLeave = async (id: any, params: any) => {
    try {
      let response = await api?.UpdateLeaveById(id, params);
      return response;
    } catch (error) {}
  };

  const handleDeleteLeave = async (id: any) => {
    try {
      let response = await api?.deleteLeaveById(id);
      return response;
    } catch (error) {}
  };

  // .............................work from home.........
  const handleWfh = async (params: any) => {
    try {
      let response = await api?.createWorkFromHome(params);
      return response;
    } catch (error) {}
  };

  const handleGetWfhDetail = async (params: any) => {
    try {
      let response = await api?.getWorkFromHome(params);
      return response;
    } catch (error) {}
  };

  const handleUpdateWfh = async (id: any, params: any) => {
    try {
      let response = await api?.UpdateWorkFromHomeId(id, params);
      return response;
    } catch (error) {}
  };

  const handleDeleteWfh = async (id: any) => {
    try {
      let response = await api?.deleteWorkFromHomeId(id);
      return response;
    } catch (error) {}
  };

  const handleAcceptAndReject = async (params: any) => {
    try {
      let response = await api?.acceptAndRejectLeave(params);
      return response;
    } catch (error) {}
  };

  const handleAcceptAndRejectWfh = async (params: any) => {
    try {
      let response = await api?.acceptAndRejectWfh(params);
      return response;
    } catch (error) {}
  };

  const handleUploadBiometric = async (params: any) => {
    try {
      let response = await api?.uploadBiometricList(params);
      return response;
    } catch (error) {}
  };

  const getBiometricList = async (params: any) => {
    try {
      let response = await api?.getAllBiometric(params);
      return response;
    } catch (error) {}
  };

  useEffect(() => {
    if (location?.pathname === "/timesheet") {
      handleGetProject({ limit: 10, page: 1 });
    }
  }, [location?.pathname]);

  const value = useMemo(
    () => ({
      createEmployeeList,
      handleGetEmployee,
      createTimesheetList,
      handleGetTimesheet,
      handleDeleteTimeSheet,
      updateTimesheet,
      createProject,
      handleGetProject,
      handleDeleteProject,
      updateProject,
      getTimeSheetById,
      handleCreateLeave,
      handleGetLeaveDetail,
      handleUpdateLeave,
      handleDeleteLeave,
      handleDeleteWfh,
      handleWfh,
      handleGetWfhDetail,
      handleUpdateWfh,
      handleAcceptAndReject,
      handleAcceptAndRejectWfh,
      projectList,
      handleUploadBiometric,
      getBiometricList,
    }),
    [projectList]
  );

  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  );
};

const useDashboard = () => {
  return React.useContext(DashboardContext);
};

export { DashboardProvider, useDashboard };
